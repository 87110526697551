<template>
<div class="d-flex mt-5">
  <div class="flex-fill"></div>
  <div class="quote-panel w-100 pt-4 m-5 mt-1">
    <h2 class="mb-4 text-primary">Windstorm Mitigation Isolated Fields Test Page</h2>
    <button v-if="!sandbox" @click="sandboxMode()">Initialize in sandbox</button>
    <div class="d-flex w-100">
      <field-column :defs="curFields" label-w="35%" field-w="65%"/>
    </div>
    <hr>
    <json-viewer :value="{
      wsFlag:itemVal('windstorm.windstormCoveredState'),
      currentParams,
      allParams,
      quoteData}" :expand-depth="2"/>

  </div>
  <div class="flex-fill"></div>
</div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import fields from '@/lib/fields/fields';
import FieldColumn from '@/components/fields/field-column';
import {quotemix} from '@/lib/quotemix';
import {aggregatedPairs} from '@/lib/fields/windstorm-data';

let statePicker = {
  key: 'wsState', type: 'radio', lbl: 'Pick State',
  height: 'auto', classList: 'mb-5 w-75',
  vals: [
    {key: 'FL', lblHead: 'FL', lbl: ` - mimic Florida quote state`},
    {key: 'SC', lblHead: 'SC', lbl: ` - mimic South Carolina quote state`},
    {key: 'AL', lblHead: 'AL', lbl: ` - mimic Alabama quote state`}
  ],
  defaultVal: 'FL'
};

export default {
  components: {FieldColumn},
  data: () => {
    return {sandbox: false};
  },
  computed: {
    ...mapGetters('getQuote', ['storeFields', 'quoteData', 'itemVal']),
    ...mapState('getQuote', ['quoteFields']),
    curFields(){

      return [
        ...this.storeFields('sandbox'),
        ...this.storeFields('windstorm')
      ];

    },
    statePickerState(){
      return this.itemVal('sandbox.wsState');
    },
    allParams(){
      return aggregatedPairs;
    },
    currentParams(){

      const pKey = k => k.includes('_') ? k.split('_')[0] : k;
      let keyVals = this.curFields
        .filter(f => !this.isType.nullOrUndef(f.val) && f._active !== false)
        .map(f => [pKey(f.key), f.val]);
      return Object.fromEntries(keyVals);
    }
  },

  methods: {
    ...mapMutations('getQuote', ['setAny', 'removeDef']),
    sandboxMode(){
      let {quoteFields} = this;
      quoteFields.sandbox = fields.initGroup([statePicker], 'sandbox');
      this.removeDef('windstorm.windstormCoveredState');
      let flagger = {key: 'windstormCoveredState', type: 'data', val: 'FL'};
      quoteFields.windstorm.addChild(flagger);
      this.setAny({quoteFields});
      this.delayFn(() => {

        quoteFields.windstorm.children.forEach(c => this.fieldState(c));
      }, 100);
      this.sandbox = true;
    }
  },
  name: 'gauntlet',
  mixins: [quotemix],
  mounted() {
    this.delayFn(this.sandboxMode);
  },
  watch: {
    statePickerState(val){
      console.log({state: val});
      this.updateField({chain: 'windstorm.windstormCoveredState', val});
    }
  }
};


</script>


