<template>
<div class="d-flex">
  <div class="flex-fill"></div>
  <div class="quote-panel w-75">
    <div class="d-flex w-100">
      <field-column :defs="flatFieldList"/>
    </div>

    <b-check v-model="flat">flat</b-check>
    <b-check v-model="asObj">as object</b-check>
    <json-viewer :value="asObj ? flatFieldsObject : flatFieldList" :expand-depth="2" v-if="flat"/>
    <json-viewer :value="quoteData" :expand-depth="2" v-else/>
  </div>
  <div class="flex-fill"></div>
</div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import fields from '@/lib/fields/fields';
import FieldColumn from '@/components/fields/field-column';
import {quotemix} from '@/lib/quotemix';

let testDefs = [{
  lbl: 'switchy',
  key: 'switch', type: 'text',
  $active: {
    test: {chain: 'sb.yesOrNo', eq: true}
  },
  val: {
    $conditional: true,
    $switch: [{
      test: { chain: 'sb.listy', eq: 'a'},
      result: 'switch : a'
    }, {
      test: { chain: 'sb.listy', eq: 'b'},
      result: 'just b'
    }, {
      test: { chain: 'sb.listy', eq: 'c'},
      result: 'see?'
    }],
    defaultVal: 'this is the d (efault) Val'
  }
},
{
  lbl: 'andy',
  key: 'and', type: 'text',
  val: {
    $conditional: true,
    $and: [
      { test: { chain: 'sb.listy', eq: 'a'} },
      { test: { chain: 'sb.yesOrNo', eq: false} }
    ],
    result: 'listy=a;yesorNo=false',
    defaultVal: 'just some val'
  }
},
{
  lbl: 'a or c',
  key: 'and', type: 'text',
  val: {
    $conditional: true,
    $or: [
      { test: { chain: 'sb.listy', eq: 'a'} },
      { test: { chain: 'sb.listy', eq: 'c'} }
    ],
    result: 'a/c on!',
    defaultVal: 'defaulty'
  }
},
{
  lbl: 'random list',
  key: 'listy', type: 'select', vals: ['a', 'b', 'c', 'd']
},
{
  key: 'yesOrNo', type: 'yn', lbl: 'Yes or No'
},

{
  lbl: 'Testing single condition', key: 'conditional', type: 'text',
  val: {
    $conditional: true,
    whenTrue: 'yup',
    whenFalse: 'Nope',
    test: {chain: 'sb.yesOrNo', eq: true}
  }
},


{
  lbl: 'plain text', key: 'plainText', type: 'text', classList: 'w-100',
  val: 'bound to plain text',
  tags: 'conditionalTest'
},
{key: 'maybeNull', type: 'data', val: 1},
{key: 'maybeEmpty', type: 'data', val: null}
];
const conditionals = fields.initGroup(testDefs, 'sb');
export default {
  components: {FieldColumn},
  data: () => {
    return {
      flat: true,
      asObj: true

    };
  },
  mixins: [quotemix],
  computed: {
    ...mapGetters('getQuote', ['flatFieldList', 'flatFieldsObject', 'quoteData'])
  },
  methods: {
    ...mapMutations('getQuote', ['setAny'])
  },
  name: 'field-test',
  mounted() {
    //console.log({conditionals});
    this.setAny({data: {sb: conditionals}});
  }
};
</script>

<style scoped>

</style>
