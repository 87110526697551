<template>
  <router-link to="/">
    <div class="logo108 text-center">
      <align-middle>
        <lio :h="27" :w="50"/>
      </align-middle>
    </div>
  </router-link>
</template>

<script>
import Lio from '@/assets/scss/svg/lio';
import AlignMiddle from '@/components/util/align-middle';

export default {
  name: 'logo',
  components: {AlignMiddle, Lio}
};
</script>

<style scoped>

</style>
