<template>
  <div>
    <router-link to="/" style="position: absolute;top:-25px;left:10px;background-color: transparent">
      <logo/>
    </router-link>
    <component :is="$route.params.page"/>
  </div>
</template>

<script>
import FieldTest from '@/views/debug/field-test';
import Gauntlet from '@/views/debug/gauntlet';
import Logo from '@/components/logo';
export default {
  data: () => {
    return {
      bg: 'white'
    };
  },
  name: 'index',
  components: {Logo, Gauntlet, FieldTest}
};
</script>
